module.exports = {
  locales: ["en", "ar"],
  defaultLocale: "en",
  pages: {
    "*": ["common", "Landing", "Auth", "Profile", "Footer"],
    "/login": ["Auth"],
    "/payment": ["Payment"],
    "/payment/status": ["Payment"],
    "/subjects/[slug]": ["Subject", "Auth", "Instructor", "Search"],
    "rgx:^/home": ["Home"],
    "/[instructor_username]": ["Instructor"],
    "/[instructor_username]/edit": ["Instructor", "Profile", "Auth"],
    "/package/[slug]": ["Packages", "Subject", "Instructor"],
    "rgx:^/profile": ["Profile"],
    "/search": ["Search", "Subject", "Footer"],
    "/live/[slug]": ["Subject", "Instructor", "Livesession", "Footer"],
    "/courses/[slug]": ["Course", "Subject"],
    "/profile": ["Profile", "Home"],
    "/profile/edit": ["Profile", "Auth"],
    "/profile/ambassador-performance": ["Profile", "Payment"],
  },
};
