import { FingerprintOptions, generateHashedFingerprint } from "hashed-device-fingerprint-js";
import { DeviceDataType } from "types/DeviceInfo";

export const deviceInfo = async (): Promise<DeviceDataType> => {
  const options: Partial<FingerprintOptions> = {
    useUserAgent: false, // Exclude the user agent (default: true)
    useLanguage: false, // Exclude the browser language
    saveToCookie: false, // Do not save the hash in a cookie (default: true)
  };

  const deviceID = await generateHashedFingerprint(options);

  return {
    device_id: deviceID || null,
    device_data: {
      app_name: navigator.appName,
      app_version: navigator.appVersion,
      user_agent: navigator.userAgent,
      vendor: navigator.vendor,
      platform: navigator.platform,
      language: navigator.language,
      maxTouchPoints: navigator.maxTouchPoints,
      isMobile: /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent), // Check if it's a mobile device
    },
    device_type: "web",
  };
};
